<template>
  <div class="warehous" v-loading="loading">
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select bdr"
                   v-model="formData.stockId" @change="getList">
          <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
        </el-select>
        <el-button type="primary" @click="getAll">
           仓库全盘
        </el-button>
        <el-button type="primary" class="mr_16"  @click="add">
           计划盘点
        </el-button>
        <div class="warning-text">零散产品盘点，直接使用PDA操作</div>
      </div>
      <div class="queryItem">
        <el-input
            v-elInput
          v-model="formData.inventoryNo"
          class="w200"
          placeholder="盘点单号"
          @keydown.enter="getList"
          clearable
        />
        <el-input
            v-elInput
            v-model="formData.createUserName"
          class="w200"
          placeholder="创建人"
          @keydown.enter="getList"
          clearable
        />
        <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList"
            clearable
        />
        <div class="queryBtn">
          <el-button type="primary" @click="getList" class="square"
            ><el-icon><Search /></el-icon
          ></el-button>
        </div>
      </div>
    </div>
    <div class="tabList">
      <!-- 列表 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="checkGoods"
        height="100%"
        class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }"
        @filter-change="filterMethod"
      >
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
        <template v-for="item in titleList" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'statusDesc'"
              align="center"
              :prop="item.fieldKey"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              filter-placement="bottom-end"
              :filters="item.filters"
              :class-name="item.className || 'table_text2'"
              :column-key="item.fieldKey"
              show-overflow-tooltip
          >
            <template #default="scope">
              <div class="status-text" :class="['status-text',`status${scope.row.status}`]">{{
                  scope.row.statusDesc
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              v-else
              align="center"
              :prop="item.fieldKey"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              filter-placement="bottom-end"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
            >
            <template v-if="item.fieldKey === 'statusDesc'" #default="scope">
              <div class="status-text" :class="['status-text',`status${scope.row.status}`]">{{ scope.row.statusDesc }}</div>
            </template>
            </el-table-column>
          </template>
      </el-table>
    </div>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
        v-model:page-size="pageInfo.pageSize"
        @current-change="handleCurrentChange"
      />
    </div>
    <OrderInfo
      ref="orderRef"
      @close="closeDetail"
      @changeOrder='changeOrder'
    ></OrderInfo>
    <get-all-record
      v-model="getAllShow"
      @close="closeDetail"></get-all-record>
  </div>
</template>
<script setup>
import {Search} from '@element-plus/icons-vue'
import GetAllRecord from './getAllRecord';
import {tableRowClassName} from "@/utils/tool.js";
import {getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {stockList} from '@/utils/api/procurement/stock.js'
import {tabTitle} from '../orderConfig.js'
import {inventoryList} from '@/utils/api/inStock/inventory.js';
import {setOrderDetail} from "@utils/tool/order.js"
import {usePagination} from "@utils/tool/page.js";
import {getDomHeight} from "@utils/tool/getDomHeight";
import Alert from '@/components/common/Alert/index.vue'

const { proxy } = getCurrentInstance();
const loading = ref(false);
const sotckList = ref([]);
const getAllShow = ref(false);
const orderRef = ref(null)
const {domHeight} = getDomHeight()

//点击仓库全盘
const getAll = () => {
  getAllShow.value = true;
}

//控组详情弹窗是否展示
const orderShow = ref(false);

//获取仓库列表
const getStockListData = async () => {
  const res = await stockList({
    all:false,
    status:true,
  })
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formData.stockId = res.data.list[0].stockId
      sotckList.value = res.data.list
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      sotckList.value.push({stockId:"",stockName:'全部仓库'})
      sotckList.value.push(...res.data.list)
    }
    getList();
  }else{
    proxy.$message.error(res.msg)
  }
}

const formData = reactive({
  stockUserName: "",//创建人
  inventoryNo:"",//盘点单号
  createUserName:"",//创建人
  stockId:'',//仓库id
  time: [],
  status: [] ,
});
const {pageInfo} = usePagination();
//获取配置文件
const  titleList  = tabTitle.columns
const filterMethod = (value, row, column)=>{
  formData.status = value.statusDesc || []
  getList()
}
const tableData = ref([]);
const getList = () => {
  tableData.value = [];
  loading.value = true;
    let beginTime = 0
  let endTime = 0
  if(formData.time && formData.time[0]){
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if(formData.time && formData.time[1]){
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  let obj = {
    createBeginTime: moment(beginTime).valueOf(),
    createEndTime: moment(endTime).valueOf(),
    pageSize: pageInfo.pageSize ,
    pageNumber: pageInfo.pageNumber ,
    ...formData,
  };
  inventoryList(obj)
    .then((res) => {
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      let list = res.data?.list || [];
      list.forEach((item) => {
        if(item.createTime){
          item.createTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
        }
      });
      tableData.value = list;
      pageInfo.total = res?.data?.total || 0 ;
    })
    .finally(() => {
      loading.value = false;
    });
};
//点击采购订单详情
const checkGoods = (e) => {
  setOrderDetail(e);
  orderRef.value.openDetail();
};

const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
onMounted(() => {
  getStockListData()
  checkOrderInfo()
});
const checkOrderInfo = () => {
  if (!proxy.$store.state.purchaseOrderInfo?.inventoryNo) return
  orderRef.value.openDetail();
};
const closeDetail = () => {
  orderRef.value.showOrderInfo = false;
  getAllShow.value = false;
  getList();
};
const add = () => {
  proxy.$emit("add");
};

const changeOrder = (orderInfo) => {
  proxy.$emit("add",orderInfo);
};

const refreshDetail = () => {
  orderRef.value.openDetail();
};


defineExpose({
  getList,
  refreshDetail,
  closeDetail
});

</script>

<script>
import { Search, Plus, ArrowDown } from "@element-plus/icons-vue";
import { reactive,  ref } from "vue";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import OrderInfo from "./orderInfo";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import moment from "moment";
export default {
  name: "warehous",
  components: {
    Search,
    Plus,
    FieldsCheck,
    OrderInfo,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.warehous {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;
  .tabList {
    margin-top: 20px;
    height: calc(100% - 32px - v-bind(domHeight));
  }
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        font-size: 14px;
      }
      .add_text {
        font-size: 14px;
        margin-left: 4px;
        height: 12px;
        line-height: 1;
        // position: relative;
        // top: 0.5px;
      }
    }
  }
  .imgList {
    img {
      width: 56px;
      height: 42px;
      border-radius: 8px;
      overflow: hidden;
    }
    .nameBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .name {
        margin-right: 10px;
        font-size: 14px;
        display: inline-block;
        @include textOverflow(5em);
      }
      .more {
        color: #73737f;
      }
    }
    .statusBtn {
      display: flex;
      justify-content: center;
    }
    .status {
      font-size: 12px;
      color: #3759ff;
      width: 56px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: rgba(55, 89, 255, 0.1);
      border-radius: 4px;
      &.status2 {
        color: #ff7b5d;
        background-color: rgba(255, 123, 93, 0.1);
      }
    }
  }
  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
