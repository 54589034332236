<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
        v-model="showOrderInfo"
        title="盘点详情"
        width="1400"
        class="goodInfo"
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @open="openDetail"
        @close="close"
    >
      <div class="zh_content">
        <div class="info">
          <div class="zh_title">基本信息</div>
          <div class="zh_introduce status3 dom-height">
            <div class="item">
              <div class="text">盘点单号：</div>
              <div class="desc">{{ orderInfo.inventoryNo }}</div>
            </div>
            <div class="item">
              <div class="text">盘点仓库：</div>
              <div class="desc">{{ orderInfo.stockName }}</div>
            </div>
            <div class="item">
              <div class="text">盘点状态：</div>
              <div class="desc status-text" :class="`status${orderInfo.status}`">{{ orderInfo.statusDesc }}</div>
            </div>
            <div class="item">
              <div class="text">创建人：</div>
              <div class="desc">{{ orderInfo.createUserName }}</div>
            </div>
            <div class="item">
              <div class="text">创建时间：</div>
              <div class="desc">{{ $filters.timeFormat(orderInfo.createTime, 1) }}</div>
            </div>
            <div class="item"
                 v-if="orderInfo.inventoryType == inventoryType.allStock || orderInfo.inventoryType == inventoryType.product">
              <div class="text">盘点方式：</div>
              <div class="desc">{{ getInventoryTypeName(orderInfo.inventoryType) }}</div>
            </div>
            <div class="item item1"
                 v-if="orderInfo.inventoryType != inventoryType.allStock && orderInfo.inventoryType != inventoryType.product">
              <div class="text">
                {{ getInventoryTypeName(orderInfo.inventoryType) }}：
              </div>
              <div class="desc">
                <span class="tags" v-for="item in orderInfo.inventoryValueNames" :key="item">{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="zh_title mt32">产品信息</div>
          <div class="tabList">
            <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                class="zh-no-table-border"
                :row-class-name="tableRowClassName"
                :cell-style="{ border: 'none' }"
                :header-row-style="{ border: 'none' }"
            >
              <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                  align="center"
                  fixed
              />
              <el-table-column
                  align="center"
                  class-name="table_text2"
                  show-overflow-tooltip
                  label="产品名称"
              >
                <template #default="scope">
                  {{ scope.row.productName }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  label="简称"
                  class-name="table_text2"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.subName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  label="产品单位"
                  class-name="table_text2"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.unitName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  class-name="table_text2"
                  label="规格/型号"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.modelName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  class-name="table_text"
                  label="产品品牌"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.brandName || "--" }}
                </template>
              </el-table-column>
              <!--              <el-table-column width="150" align="center" class-name="table_text"-->
              <!--                               label="账面数量">-->
              <!--                <template #default="scope">-->
              <!--                  <div class="table_text2">-->
              <!--                    {{ scope.row.stockCount }}-->
              <!--                  </div>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
            </el-table>
          </div>
          <div class="remark">
            <el-pagination
                v-model:current-page="pageInfo.pageNumber"
                layout="total, prev, pager, next , jumper"
                :total="pageInfo.total"
                v-model:page-size="pageInfo.pageSize"
                @current-change="handleCurrentChange"
            />
            <div class="btnBox"
                 v-if="orderInfo.inventoryType == inventoryType.allStock">
              <el-button class="btn1 zh-btn info_btn" @click="delOrder"
                         v-if='orderInfo.status == orderConfig.invalid || orderInfo.status == orderConfig.cancel'>
                删除
              </el-button>
              <el-button type="primary" class="btn1 info_btn ml_16" @click="cancelOrder"
                         v-if='orderInfo.status == orderConfig.wait '>
                取消盘点
              </el-button>
            </div>
            <div class="btnBox"
                 v-else-if="orderInfo.status == orderConfig.cancel && orderInfo.inventoryType !=inventoryType.allStock">
              <el-button @click="delOrder" class="btn1 zh-btn info_btn">删除</el-button>
              <el-button type="primary" class=" btn1 info_btn ml_16" @click="changeOrder">编辑</el-button>
            </div>
            <div class="btnBox"
                 v-else-if="orderInfo.status == orderConfig.wait  && orderInfo.inventoryType != inventoryType.allStock">
              <el-button type="primary" class=" btn1 info_btn ml_16" @click="cancelOrder">取消盘点</el-button>
            </div>
            <div class="btnBox" v-else-if="orderInfo.status == orderConfig.invalid ">
              <el-button class="btn1 zh-btn info_btn" @click="delOrder">删除</el-button>
            </div>
            <div class="btnBox" v-else></div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script setup>
import {resetObj, tableRowClassName} from "@/utils/tool.js";
import {getCurrentInstance, reactive, ref} from "vue";
import {useStore} from "vuex";
import {ElMessageBox} from "element-plus";
import {
  inventoryAddGoodsListSelect,
  inventoryCancel,
  inventoryDel,
  inventoryDetail
} from '@/utils/api/inStock/inventory.js';
import {getInventoryTypeName, inventoryType, orderConfig} from '../orderConfig';
import {useLoading} from "@utils/tool/loading";


const {setLoading} = useLoading();
const domHeight = ref('')
const { proxy } = getCurrentInstance();
const showOrderInfo = ref(false);
const emit = defineEmits(["close"]);
const store = useStore();
const pageInfo = reactive({
  pageNumber: 1,
  pageSize: 10,
  total: 0,
});

const handleCurrentChange = (val)=>{
  pageInfo.pageNumber = val
  getGoodsList()
}
//删除订单
const delOrder = () => {
  ElMessageBox.confirm("确认删除该订单！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    inventoryDel({
     inventoryNo: orderInfo.inventoryNo,
    }).then((res) => {
      if (res.code === 6999) {
        proxy.$message.error(res.msg);
        return
      }
      if (res.code !== 0 && res.code !== 6999) {
        ElMessageBox.confirm(res.msg, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "zh-message-box",
          showCancelButton:false,
        })
          .then(() => {
            emit("close");
          })
          .catch(() => {
            emit("close");
          });
        return;
      }
      proxy.$message.success("删除成功");
      emit("close");
    });
  });
};
//取消盘点
const cancelOrder = ()=>{
  ElMessageBox.confirm("确认取消该盘点任务！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    inventoryCancel({
      inventoryNo: orderInfo.inventoryNo,
    }).then((res) => {
      if (res.code === 6999) {
        proxy.$message.error(res.msg);
        return
      }
      if (res.code != 0 && res.code != 6999) {
        ElMessageBox.confirm(res.msg, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          customClass: "zh-message-box",
          showCancelButton:false,
        })
          .then(() => {
            emit("close");
          })
          .catch(() => {
            emit("close");
          });
        return;
      }
      proxy.$message.success("取消成功");
      openDetail()
    });
  });
}

//编辑订单
const changeOrder = () => {
  proxy.$emit("changeOrder",
    orderInfo);
};

const orderInfo = reactive({
  orderSn: "",
  supplierName: "",
  productName: [],
  createTime: "",
  createUserName: "",
  remark: "",
  orderStatus: 0,
});

const tableData = ref([]);

const openDetail = () => {
  setLoading(true);
  resetObj(orderInfo);
    reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
    getOrderInfo()
};

//获取盘点详情
function getOrderInfo(){
  inventoryDetail({
    inventoryNo: orderInfo.inventoryNo,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(orderInfo, res.data);
    if(res.data.inventoryValueNames){
      orderInfo.inventoryValueNames = res.data.inventoryValueNames.split(',')
    }
    if (orderInfo.inventoryValues) {
      orderInfo.inventoryValues = orderInfo.inventoryValues.split(',')
    }
    getGoodsList();
  });
}

//获取产品列表
const getGoodsList = () => {
  inventoryAddGoodsListSelect({
    inventoryNo: orderInfo.inventoryNo,
    stockId: orderInfo.stockId,
    pageNumber: pageInfo.pageNumber,
    pageSize: pageInfo.pageSize,
    inventoryType:orderInfo.inventoryType,
    inventoryValues:orderInfo.inventoryValues,
    goodsType:orderInfo.goodsType,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    if (res.data?.list?.length > 0) {
      res.data.list.forEach((item) => {
      });
    }
    tableData.value = res.data?.list || [];
    pageInfo.total = res.data?.total || 0;
    setTimeout(() => {
      domHeight.value = document.querySelector('.dom-height').offsetHeight + 'px'
    }, 100)
    showOrderInfo.value = true;
    setLoading(false);
  });
};
const close = () => {
  proxy.$emit("close");
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  tableData.value = [];
};
defineExpose({
  openDetail,
  showOrderInfo
});
</script>

<script>
import RfidList from "@/components/rfidList/indexByOrder.vue";
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { reactiveAssign } from "@/utils/tool.js";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  name: "inventoryRecordOrderInfo",
  components: {
    ArrowDown,
    RfidList,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_introduce{
      .status-text{
    font-size: 14px;
    &.status1{
      color: #FF910A;
    }
    &.status2{
      color: #3160F6;
    }
    &.status3{
      color: #AAAAB2;
    }
  }
      &.status3{
        height: auto;
        padding-bottom: 0;
      }
      .item1{
        @include textOverflow(100%);
        width: 100%;
        align-items: flex-start;

        .text {
          height: 28px;
          line-height: 28px;
        }

        .desc {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: -8px;

          .tags {
            border-radius: 8px;
            background-color: #fff;
            margin-right: 8px;
            margin-bottom: 8px;
            border: 1px solid #DCDEE0;
            width: max-content;
            padding: 0 8px;
          }
        }
      }
    }
    .tabList {
      $height: calc(668px - v-bind(domHeight));
      height: $height;
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
        &.top1 {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }

  }
  .remark{
    border-top: 1px solid #DCDEE0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-top: 24px;
    position: absolute;
    bottom: 8px;
    left: 32px;
    width: calc(100% - 64px);
    .info_btn {
          width: 112px;
        }
    &.ntop{
      border-top: none;
    }
  }
}
</style>
