<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-16 09:51:42
 * @FilePath: /aurora-carton-web/src/views/inStock/inventoryRecord/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="proOrder animate__zoom animate__animated" v-show="!addShow">
    <div class="content">
      <el-tabs v-model="tabChecked" @tab-click="tabCheck">
        <el-tab-pane :label="tabText" name="complete">
          <Complete v-show='tabChecked === "complete"' ref="completeList" @add="handleAdd"/>
        </el-tab-pane>
        <el-tab-pane :label="tabTextIn" name="incomplete">
          <Incomplete v-show="tabChecked === 'incomplete'"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <AddOrder v-show="addShow" ref='addOrder' @close="closeAdd" @closeDetail="closeDetail"></AddOrder>
</template>

<script>
import {reactive, ref, toRefs} from 'vue'
import Complete from './components/complete';
import Incomplete from './components/incomplete';
import AddOrder from "./addOrder/index";
import {useEventListener} from "@/utils/tool/event.js"; // 工具函数
import {ElMessageBox} from "element-plus";

export default {
  name:"orderList",
  components:{
    Complete,
    Incomplete,
    AddOrder
  },
  setup () {
    const addShow = ref(false)
    const addOrder = ref(null)
    const completeList = ref(null)
    const state = reactive({
      tabChecked:"complete",
      tabText:'盘点列表',
      tabTextIn:'盘点记录',
    })
    const tabCheck = function(tab,e){
    }
    const handleAdd = function (orderInfo){
      if(orderInfo){
        addOrder.value.openAdd(orderInfo)
      }else{
        addOrder.value.openAdd()
      }

      addShow.value = true
    }
    const closeAdd = (refGoods)=>{
      addShow.value = false
      completeList.value.getList()
      if(refGoods){
        completeList.value.closeDetail()
      }
    }
    const closeDetail = () => {
      addShow.value = false
      completeList.value.closeDetail()
    }
    function listenBack(){
      history.pushState(null, null, document.URL);
      useEventListener(window,'popstate',goBack)
    }
    listenBack()
    function goBack(){
      if(addShow.value){
        ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        customClass: "zh-message-box",
        }).then(() => {
          addShow.value = false
          history.pushState(null, null, document.URL);
        })
      }else{
        history.go(-1)
      }
    }
    return {
      ...toRefs(state),
      tabCheck,
      addShow,
      handleAdd,
      closeAdd,
      addOrder,
      completeList,
      closeDetail
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
.proOrder{
  height: 100%;
  padding:16px;
  box-sizing: border-box;
  position: relative;
  >.content{
    border-radius: 16px;
    background: #fff;
    padding: 12px 32px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  :deep(.el-tabs__content) {
    height: 96%;
  }

  :deep(.el-tabs) {
    height: 100%;

    .is-top {
      margin-bottom: 0;
    }
  }
}


</style>
