<template>
  <div class="addInventory animate__zoom animate__animated" v-loading="loading">
    <div class="zh-content">
      <div class="zh-add-title">{{formData.isEdit?'编辑盘点':'新增盘点'}}</div>
      <div class="query-box queryBoxOne">
        <div class="btnBox">
          <el-select placeholder="请选择仓库" v-if="!formData.isEdit" popper-class="no-border-select-popper"
                     class="no-boder-select bdr" v-model="formData.stockId">
            <el-option v-for="item in stocksList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
          </el-select>
          <div class="text" v-else>盘点仓库：{{ formData.stockName }}</div>
        </div>
        <div class="query-type" v-if="formData.stockId">
          <div class="type-name"><span class="red">*</span>盘点方式</div>
          <el-radio-group class="radio-group" v-if="!formData.isEdit" v-model="activeType" @change="changeType">
            <el-radio label="0" size="large" >按产品盘点</el-radio>
            <el-radio label="1" size="large" :class="activeType == 1?'active':''">按供应商盘点</el-radio>
            <el-radio label="2" size="large" :class="activeType == 2?'active':''">按产品分类盘点</el-radio>
            <el-radio label="3" size="large" :class="activeType == 3?'active':''">按产品品牌盘点</el-radio>
          </el-radio-group>
          <div v-else class="text">{{ getTypeName }}</div>
        </div>
      </div>
      <div class="query-search" :class="activeType != 0 ?'active':''" v-if="formData.stockId">
        <div class="title"><span class="red">*</span>{{ typeTitle }}</div>
        <div class="user-input ml_16" v-if="!formData.isEdit">
          <el-cascader
          v-if="activeType != 0"
          :disabled="formData.isEdit"
          v-model="formData.inventoryValues"
          :placeholder="'请填写' + typeTitle"
          :options="selectList"
          :props="cassderProps"
          class="w240 stock"
          collapse-tags
          collapse-tags-tooltip
          clearable
        />
        </div>
        <div v-else class="ml_16">
          <span class="tags" v-for="item in  formData.inventoryValueNames" :key="item">{{ item }}</span>
          </div>
      </div>
      <el-tabs v-model="tabChecked" class="demo-tabs" @tab-click="tabCheck" v-if="formData.stockId">
        <el-tab-pane :label="'未选择产品('+ census.unChoose +')'" name="complete">
        </el-tab-pane>
        <el-tab-pane :label="'已选择产品('+ census.choose +')'" name="incomplete">
        </el-tab-pane>
      </el-tabs>
      <div class="queryBoxOne" v-if="formData.stockId">
        <el-checkbox label="仅看有库存产品" size="large" v-if="tabChecked === 'complete'" v-model="formData.inStock"/>
        <div v-else style="height: 40px"></div>
        <el-input
          v-model="formData.query"
          class="w280"
          placeholder="产品名称/简称/简拼/条码"
          :suffix-icon="Search"
          v-elInput
          clearable
        />
      </div>
      <div class="tabList" v-if="formData.stockId" :class="activeType != 0 ?'active':''">
        <el-table :data="tableData" style="width: 100%" height="100%" class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }">
          <el-table-column label="序号" width="100" align="center" fixed>
            <template #default="scope">
              <div class="table_text">
                {{
                  (pageInfo.pageNumber - 1) * pageInfo.pageSize +
                  scope.$index +
                  1
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" class-name="table_text2" label="产品名称" fixed>
            <template #default="scope">
                {{ scope.row.productName }}
            </template>
          </el-table-column>
          <el-table-column width="180" align="center" label="简称" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.subName }}
            </template>
          </el-table-column>

          <el-table-column width="160" align="center" class-name="table_text2" label="产品单位" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.unitName }}
            </template>
          </el-table-column>
             <el-table-column

            align="center"
            label="规格/型号"
            show-overflow-tooltip
          >
            <template #default="scope">{{ scope.row.modelName }}
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" label="产品品牌" show-overflow-tooltip>
            <template #default="scope">
              {{ scope.row.brandName || '--' }}
            </template>
          </el-table-column>
          <el-table-column width="200"  align="center" class-name="table_text" label="上次盘点时间" show-overflow-tooltip>
            <template #default="scope">{{ $filters.timeFormat(scope.row.inventoryTime, 1) }}
            </template>
          </el-table-column>
          <el-table-column width="160" align="center">
            <template #header>
              <div class="cur_p c_blue" :class="tableData.length === 0 ?'disabled':''" @click="addAll"
                   v-if="tabChecked=='complete'">+全部添加
              </div>
              <div class="cur_p c_blue" :class="tableData.length === 0 ?'disabled':''" @click="delAll" v-else>全部移除
              </div>
            </template>
            <template #default="scope">
              <div class="cur_p c_blue" v-if="tabChecked == 'complete'" @click="addGood(scope.row)">添加</div>
              <div class="cur_p c_blue" v-else @click="delGoods(scope.row)">移除</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="remark" >
          <el-pagination
          v-if="formData.stockId"
            v-model:current-page="pageInfo.pageNumber"
            layout="total, prev, pager, next , jumper"
            :total="pageInfo.total"
            v-model:page-size="pageInfo.pageSize"
            @current-change="handleCurrentChange"
          />
          <div v-else></div>
          <div class="btnBox">
            <el-button class="btn1 zh-btn w120" @click="cancel" v-if="!formData.isEdit">取消</el-button>
           <el-button class="main btn1 w120" type="primary"  @click="confirm">{{formData.isEdit?'确认提交':'创建盘点'}}</el-button>
          </div>
        </div>
    </div>
  </div>
</template>
<script setup>
import {ElMessageBox} from "element-plus";
import {Search} from '@element-plus/icons-vue'
import {computed, getCurrentInstance, reactive, ref, watch} from "vue";
import {stockList} from "@/utils/api/procurement/stock.js";
import {
  inventoryAdd,
  inventoryAddEditGoods,
  inventoryAddEditGoodsAll,
  inventoryAddGoods,
  inventoryAddGoodsAll,
  inventoryAddGoodsList,
  inventoryAddGoodsListSelect,
  inventoryDelEditGoods,
  inventoryDelEditGoodsAll,
  inventoryDelGoods,
  inventoryDelGoodsAll,
  inventoryEdit
} from '@/utils/api/inStock/inventory.js';
import {guid, tableRowClassName} from "@/utils/tool.js";
import {brandList,} from "@/utils/api/product/brand.js";
import {supplierQuery,} from "@/utils/api/supplier.js";
import {typeInfo} from "@/utils/api/type.js"; // 分类接口
import {unionBy} from 'lodash'


const needOne = false
const { proxy } = getCurrentInstance();
const tabChecked = ref('complete');
const activeType = ref('0')
const formData = reactive({
  stockId:'',
  activeType : '0',
  inStock:false,
  query:'',
  inventoryNo:"",
  inventoryValues:[],
  isEdit:false,
  inventoryValueNames:[]
});
const stocksList = ref([])
const tableData = ref([])
const remark = ref('')
const  selectList = ref([])
const cassderProps = {
  value: "value",
  label: "label",
  children: "childList",
  multiple: true,
};

const query = ()=>{
  let data = cloneDeep(formData)
  return {
    stockId:data.stockId,
    inventoryType:activeType.value,
    query:data.query,
    inventoryValues: flatten(data.inventoryValues, true),
    goodsType:data.inStock? 1 : 2,
    inventoryNo:data.inventoryNo,
    inventoryValueNames: setInventoryValueNames(true),
  }
}

//扁平化数组
const flatten = (arr, isEdit) => {
  let newArr = []
  arr.forEach((item)=>{
    if(Array.isArray(item)){
      newArr.push(...item)
    }else{
      if (needOne || isEdit) newArr.push(item)
    }
  })
  return unionBy(newArr)
}

function getInventoryNo(){
  formData.inventoryNo = 'PD'+ guid()
}


//获取供应商列表
function getSupplierList() {
  supplierQuery({
    buyBack: true
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    selectList.value = handleList(res.data.list || []);
  });
}

//获取分类列表
function getTypeList() {
  typeInfo().then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data.list || [];
    list.forEach((item) => {
      item.value = item.classifyId;
      item.label = item.classifyName;
      if(item.childList?.length){
        item.childList.forEach((item2) => {
        item2.value = item2.classifyId;
        item2.label = item2.classifyName;
      });
      }
    });
    list = list.filter((item) => item.childList && item.childList?.length)
    selectList.value = list
  });
}

//获取品牌列表
function getBrandList() {
  brandList().then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    selectList.value = handleList(res.data.list || []);
  });
}

//处理供应商，分类，品牌列表
function handleList(list) {
  let list2 = []
   list.forEach((item) => {
    //将item.list的每一个元素添加到list2中
    list2.push(...item.list);
  });
  return list2.map((item) => {
    return {
      label: item.supplierName || item.brandName,
      value: item.supplierId ||  item.brandId,
    };
  });
}

//盘点方式表头
const typeTitle = computed(()=>{
  switch(activeType.value){
    case '0':
      return ''
    case '1':
      return '供应商'
    case '2':
      return '产品分类'
    case '3':
      return '产品品牌'
  }
})

//切换盘点方式
const changeType = ()=>{
  formData.inventoryValues = []
  switch(activeType.value){
    case '0':
      break
    case '1':
      getSupplierList()
      break
    case '2':
      getTypeList()
      break
    case '3':
      getBrandList()
      break
  }
}



const handleCurrentChange =(val)=>{
  pageInfo.pageNumber = val;
  resetList();
}

const pageInfo = reactive({
  pageNumber: 1,
  pageSize: 10,
  total: 0,
});

function resetPageInfo(){
  pageInfo.pageNumber = 1
  pageInfo.pageSize = 10
  pageInfo.total = 0
}

const tabCheck =()=>{
  if(activeType.value != 0 && formData.inventoryValues?.length == 0){
    resetPageInfo()
    tableData.value = []
    census.choose = 0
    census.unChoose = 0
    return
  }
  resetPageInfo()
  resetList();
}

const census = reactive({
  unChoose:0,
  choose:0
})

//获取未选择产品列表
const getUnChooseList = ()=>{
  inventoryAddGoodsList({
    ...pageInfo,
    ...query()
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    if(tabChecked.value == 'complete'){
      tableData.value = res.data?.list || []
      pageInfo.total = res.data?.total || 0
    }
    census.unChoose = res.data?.total || 0
  })
}

//获取已选择产品列表
const getChooseList = ()=>{
  inventoryAddGoodsListSelect({
    ...pageInfo,
    ...query(),
    goodsType: '',
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    if(tabChecked.value != 'complete'){
      tableData.value = res.data?.list || []
      pageInfo.total = res.data?.total || 0
    }
    census.choose = res.data?.total || 0
  })
}

const loading = ref(false)

//刷新列表
const resetList = ()=>{
  loading.value = true
  Promise.all([
    getChooseList(),
    getUnChooseList()
  ]).then(res=>{
    loading.value = false
  })
}

//获取仓库列表
const getSotckList = () => {
  stockList({
    status : true,
    all: false,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    if (res.data?.list?.length && !formData.isEdit) {
      formData.stockId = res.data.list[0].stockId
    }
    stocksList.value = res.data.list || [];
  });
};

//全部添加
const addAll = ()=>{
  if (tableData.value.length === 0) {
    return
  }
  let api = formData.isEdit ? inventoryAddEditGoodsAll : inventoryAddGoodsAll
  api({
    ...query()
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('操作成功')
    resetList()
  })
}

//全部移除
const delAll = ()=>{
  if (tableData.value.length === 0) {
    return
  }
  let api = formData.isEdit ? inventoryDelEditGoodsAll : inventoryDelGoodsAll
  api({
    ...query()
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('操作成功')
    resetList()
  })
}

//添加产品
const addGood =(e)=>{
  let api = formData.isEdit ? inventoryAddEditGoods : inventoryAddGoods
  api({
    ...query(),
    vo:e
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('添加成功')
    resetList()
  })
}


//移除
const delGoods = (e) => {
  if(tableData.value.length == 0){
    return
  }
  let api = formData.isEdit ? inventoryDelEditGoods : inventoryDelGoods
  api({
   ...query(),
   vo:e
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('移除成功')
    resetList()
  })
};




// watch(()=>activeType,(val)=>{
//   resetPageInfo()
//   resetList()
// },{deep:true})
let isFirst = true
watch(()=>formData,(val)=>{
  if(isFirst){
    isFirst = false
    return
  }
  if(activeType.value != 0 && formData.inventoryValues.length == 0){
    resetPageInfo()
    tableData.value = []
    census.choose = 0
    census.unChoose = 0
    return
  }
  resetPageInfo()
  resetList()
},{deep:true})

//取消
const cancel = () => {
  ElMessageBox.confirm("信息未保存，是否确认取消？", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
    }).then(() => {
     proxy.$emit('close')
    })
};

//确认
const confirm = () => {
  if(!formData.stockId){
    proxy.$message.error('请填写选择仓库')
    return
  }
  if(census.choose == 0){
    proxy.$message.error('请先添加产品')
    return
  }
  let msg = formData.isEdit ? '确认编辑该盘点？' : '确认创建该盘点？'
  ElMessageBox.confirm(msg, "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  }).then(() => {
    let api = formData.isEdit ? inventoryEdit : inventoryAdd
    api({
    ...query(),

    }).then((res) => {
      if (res.code === 0) {
        let msg = formData.isEdit ? '盘点编辑成功' : '盘点创建成功'
        proxy.$message.success(msg)
        proxy.$emit('close',formData.isEdit)
      }else{
        if (res.code !== 6999) {
          ElMessageBox.confirm(res.msg, "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            customClass: "zh-message-box",
            showCancelButton: false,
          })
              .then(() => {
                proxy.$emit("closeDetail");
              })
              .catch(() => {
                proxy.$emit("closeDetail");
              });
          return;
        }
        proxy.$message.error(res.msg)
      }
    });
  });
};

//设置inventoryValueNames
function setInventoryValueNames(isEdit) {
  if(activeType.value == 0){
    return ['按产品盘点']
  }
  let arr = []
  let arr2 =cloneDeep(formData.inventoryValues)
  flatten(arr2).forEach((item)=>{
      selectList.value.find((item2)=>{
      if(item2.childList?.length){
          item2.childList.find((item3)=>{
          if(item3.value == item){
            arr.push(item3.label)
            return
          }
        })
        if (needOne || isEdit) {
          if (item2.value == item) {
            arr.push(item2.label)
            return
          }
        }
      } else {
        if (item2.value == item) {
          arr.push(item2.label)
          return
        }
      }
    })
  })
  return arr
}

//设置订单信息
function setOrder(orderInfo){
    formData.inventoryNo = orderInfo.inventoryNo
    formData.stockId = orderInfo.stockId
    formData.stockName = orderInfo.stockName
    formData.inventoryValueNames = orderInfo.inventoryValueNames || []
    formData.inventoryValues = orderInfo.inventoryValues || []
    activeType.value = orderInfo.inventoryType.toString()
    formData.isEdit = true
}

function clearData(){
  isFirst = true
  tableData.value = []
  census.choose = 0
  census.unChoose = 0
  formData.inventoryNo = ''
  formData.stockId = ''
  formData.stockName = ''
  formData.inventoryValueNames = []
  activeType.value = '0'
  formData.isEdit = false
  formData.inStock = false
  formData.query = ''
  formData.inventoryValues = []
  selectList.value = []
  resetPageInfo()
}

const openAdd = (orderInfo)=>{
  clearData()
  if(orderInfo){
   setOrder(orderInfo)
   resetList()
  }else{
    getInventoryNo()
    formData.isEdit = false
  }
  getSotckList();
}



const getTypeName = computed(()=>{
  switch(activeType.value){
    case '0':
      return '按产品盘点'
    case '1':
      return '按供应商盘点'
    case '2':
      return '按产品分类盘点'
    case '3':
      return '按产品品牌盘点'
  }
})


defineExpose({
  openAdd
})
</script>
<script>
import { ArrowDown, Search } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import { cloneDeep } from 'lodash';
export default {
  name: "addOrder",
  components: {
    Search,
    ArrowDown,
    SelectGoodsFromList,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss">
@import "./index.scss";
:deep(.el-table__row) {
  cursor: pointer;
}
</style>
