<template>
  <div class="warehous" v-loading="loading">
    <div class="queryBoxOne">
      <div class="btnBox">
        <el-select placeholder="请选择仓库" popper-class="no-border-select-popper" class="no-boder-select bdr"
                   v-model="formData.stockId" @change="getList">
          <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
        </el-select>
      </div>
      <div class="queryItem">
        <el-input
            v-elInput
          v-model="formData.inventoryNo"
          class="w200"
          placeholder="盘点单号"
          @keydown.enter="getList"
          clearable
        />
        <el-input
            v-elInput
            v-model="formData.createUserName"
          class="w200"
          placeholder="创建人"
          @keydown.enter="getList"
          clearable
        />
        <el-input
            v-elInput
          v-model="formData.stockUserName"
          class="w200"
          placeholder="完成人"
          @keydown.enter="getList"
          clearable
        />
        <el-date-picker
            v-model="formData.time"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="创建开始日期"
            end-placeholder="创建结束日期"
            @change="getList"
            clearable
        />
        <el-date-picker
            v-model="formData.overTime"
            type="daterange"
            class="w280"
            range-separator="至"
            start-placeholder="完成开始日期"
            end-placeholder="完成结束日期"
            @change="getList"
            clearable
        />
        <div class="queryBtn">
          <el-button type="primary" @click="getList" class="square"
            ><el-icon><Search /></el-icon
          ></el-button>
        </div>
      </div>
    </div>
    <div class="tabList">
      <!-- 列表 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="checkGoods"
        height="100%"
        class="zh-no-table-border"
        :row-class-name="tableRowClassName"
        :cell-style="{ border: 'none' }"
        :header-row-style="{ border: 'none' }"
      >
        <el-table-column
          type="index"
          width="100"
          label="序号"
          align="center"
          fixed
        />
        <template v-for="item in titleListIn" :key="item.fieldKey">
            <el-table-column
              align="center"
              :prop="item.fieldKey"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text2'"
              show-overflow-tooltip
            >
            </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="mainPage">
      <el-pagination
        v-model:current-page="pageInfo.pageNumber"
        layout="total, prev, pager, next , jumper"
        :total="pageInfo.total"
        v-model:page-size="pageInfo.pageSize"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 订单详情 -->
    <OrderInfo
        ref="orderDetail"
      @close="closeDetail"
    ></OrderInfo>
  </div>
</template>
<script setup>
import {tableRowClassName} from "@/utils/tool.js";
import {tabTitle} from '../orderConfig.js'
import {getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {stockList} from '@/utils/api/procurement/stock.js'
import {inventoryComplete} from '@/utils/api/inStock/inventory.js';
import {getDomHeight} from "@utils/tool/getDomHeight"

const { proxy } = getCurrentInstance();
const loading = ref(false);
const sotckList = ref([])
//控组详情弹窗是否展示
const orderShow = ref(false);
const {domHeight} = getDomHeight('.queryBoxOne');
const orderDetail = ref(null)
//获取仓库列表
const getStockListData = async () => {
const res = await stockList({
    all:false,
    status:true,
  })
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formData.stockId = res.data.list[0].stockId
    sotckList.value = res.data.list
  }else if(res.data?.list?.length  && res.data?.list?.length > 1){
    sotckList.value.push({stockId:"",stockName:'全部仓库'})
    sotckList.value.push(...res.data.list)
  }
  getList();
}else{
  proxy.$message.error(res.msg)
}
}

/*
  关于统计
*/
const formData = reactive({
  inventoryNo:"",//盘点单号
  createUserName:"",//创建人
  stockId:'',//仓库id
  time: [],//创建时间
  overTime:[],//结束时间
  stockUserName:'',//完成人
});
const pageInfo = reactive({
  pageSize: 20,
  pageNumber: 1,
  total: 0,
});
//获取配置文件
const  titleListIn  = tabTitle.inColumns
const tableData = ref([]);
const getList = () => {
  loading.value = true;
    let beginTime = 0
  let endTime = 0
  if(formData.time && formData.time[0]){
    beginTime = moment(formData.time[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if(formData.time && formData.time[1]){
    endTime = moment(formData.time[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  let beginTime1 = 0
  let endTime1 = 0
  if(formData.overTime && formData.overTime[0]){
    beginTime1 = moment(formData.overTime[0]).format('YYYY-MM-DD') + ' 00:00:00'
  }
  if(formData.overTime && formData.overTime[1]){
    endTime1 = moment(formData.overTime[1]).format('YYYY-MM-DD') + ' 23:59:59'
  }
  let obj = {
    createBeginTime: moment(beginTime).valueOf(),
    createEndTime: moment(endTime).valueOf(),
    stockBeginTime:moment(beginTime1).valueOf(),
    stockEndTime:moment(endTime1).valueOf(),
    pageSize: pageInfo.pageSize ,
    pageNumber: pageInfo.pageNumber ,
    ...formData,
  };
  inventoryComplete(obj)
    .then((res) => {
      if (res.code !== 0) {
        proxy.$message.error(res.msg);
        return;
      }
      tableData.value = res.data?.list || [];
      tableData.value.forEach((item) => {
        item.stockTime = proxy.$filters.timeFormat(item.stockTime, 1)
        item.createTime = proxy.$filters.timeFormat(item.createTime, 1)
      });
      pageInfo.total = res?.data?.total || 0;
      proxy.$emit("change");
      setTimeout(() => {
        loading.value = false;
      }, 100);
    })
    .finally(() => {
      loading.value = false;
    });
};
//点击采购订单详情
const checkGoods = (e) => {
  proxy.$store.commit("PURCHASE_ORDER_INFO",e);
  orderDetail.value.showOrderInfo = true;
};
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
};
onMounted(() => {
  getStockListData()
});
const closeDetail = () => {
  orderDetail.value.showOrderInfo = false;
  getList();
};
</script>

<script>
import { Search, Plus, ArrowDown } from "@element-plus/icons-vue";
import { reactive, ref } from "vue";
import FieldsCheck from "@/components/fieldsCheck/index.vue";
import OrderInfo from "./inStockInfo.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import moment from "moment";
export default {
  name: "warehous",
  components: {
    Search,
    Plus,
    FieldsCheck,
    OrderInfo,
    ArrowDown,
    SelectByAbcModal,
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
.warehous {
  padding-top: 8px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 70px;
  .tabList {
    margin-top: 20px;
    height: calc(100% - 32px - v-bind(domHeight));
  }
  .queryBoxOne {
    .add_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        font-size: 14px;
      }
      .add_text {
        font-size: 14px;
        margin-left: 4px;
        height: 12px;
        line-height: 1;
        // position: relative;
        // top: 0.5px;
      }
    }
  }
  .queryBoxOne {
    margin-top: 16px;
  }
}

:deep(.el-range-separator) {
  font-weight: 400;
  color: $fsColor2;
}
</style>
