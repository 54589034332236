<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-10 16:37:23
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-16 11:13:36
 * @FilePath: /aurora-carton-web/src/views/inStock/inventoryRecord/components/getAllRecord.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- 产品详情弹窗 -->
  <el-dialog
    v-bind="$attrs"
    title="选择仓库"
    width="480"
    class="zh-all-record"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @open="openDetail"
    @close="close"
  >
    <div class="zh_content">
      <div class="reset-single">
        <div class="reset-key"><span class="red">*</span>盘点仓库</div>
        <div class="reset-value">
          <el-select
            v-model="formData.stockId"
            placeholder="请选择"
            :class="[isErr?'err':'','w320']"
            clearable
            >
               <el-option v-for="item in stocksList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
          </el-select>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button class="btn1 zh-btn"
      @click="close"
        >取消</el-button
      >
      <el-button class="main btn1" type="primary" @click="handleOkAdd"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>
<script setup>
import {getCurrentInstance, reactive, ref} from "vue";
import {inventoryAdd} from '@/utils/api/inStock/inventory.js';
import {stockList} from '@/utils/api/procurement/stock.js';
import {guid} from "@/utils/tool.js";
import {checkErr} from '@/utils/tool/isErr.js';
import {inventoryType} from '../orderConfig'

const { proxy } = getCurrentInstance();
const emit = defineEmits(["close"]);
const stocksList = ref([]);
const formData = reactive({
  stockId: "",
  inventoryNo:'',
  inventoryType: inventoryType.allStock,
  goodsType:0,
  inventoryValueNames:['全部盘点']
});
const { isErr , setErr} = checkErr({
  msg:'请选择盘点仓库'
});

//获取仓库列表
const getStocksList = ()=>{
  stockList({
    all:false,
    status:true
  }).then(res=>{
    if(res.code == 0){
      if (res.data?.list?.length) {
        formData.stockId = res.data.list[0].stockId
      }
      stocksList.value = res.data.list
    }else{
      proxy.$message.error(res.msg)
    }
  })
}
const openDetail = () => {
  getStocksList()
  formData.inventoryNo ='PD' + guid()
};

const handleOkAdd = ()=>{
  if(!formData.stockId){
    setErr()
    return
  }
  inventoryAdd({
    ...formData
  }).then(res=>{
    if(res.code == 0){
      proxy.$message.success('操作成功')
      proxy.$emit("close");
    }else{
      proxy.$message.error(res.msg)
    }
  })
}

const close = () => {
  proxy.$emit("close");
  formData.stockId = ''
};

</script>

<style lang="scss" scoped>
.reset-single {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .reset-key {
    width: 100px;
    text-align: right;
    margin-right: 16px;
  }

    .reset-value {
      width: 320px;
      .w320{
        width: 320px;
      }
    }
  }
</style>
